import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import Notifications from '@kyvg/vue3-notification'

const vuetify = createVuetify({
  components,
  directives,
})

axios.defaults.baseURL = process.env.VUE_APP_AGENCIA_ORIGIN+'api/v1';
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});
axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('access_token')
    router.push('/login')
  }
  return Promise.reject(error);
});
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';


const app = createApp(App)
app.directive('click-outside', {
  beforeMount: function (el, binding) {
    el.clickOutsideEvent = function (event) {
      // check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // if it did, call method provided in attribute value
        binding.value(event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

app.use(router)
app.use(vuetify)
app.use(Notifications)
app.mount('#app')
