<template>
  <v-app>
    <router-view/>
    <notifications/>
  </v-app>
  
</template>

<script>


export default {
  name: 'App',
  
}
</script>

<style>
	.vue-notification {
		margin: 80px 5px 5px !important; 
		padding: 15px !important;
		font-size: 17px !important; 
	}
</style>
