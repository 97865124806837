<template>
    <div class="client-involved-form">
        <div class="aux-content-container">
            <div class="header">
                <img :src="shareitLogo" alt="Logo">
                <div class="form-id">
                    <div>
                        <span>
                            COMPANY:
                        </span>
                        <span>
                            {{ client.companyName }}
                        </span>
                    </div>
                    <div>
                        <span>
                            N°:
                        </span>
                        <span>
                            {{ client.codeNum }}
                        </span>
                    </div>
                </div>

            </div>

            <div class="form-description">
                <h1>NEW CLIENT FORM1</h1>

                <p>Welcome to Share It Studio! We're thrilled to begin this journey together.
                </p>
                <p>To ensure we provide you with the best service, please take a moment to complete this essential form.
                    Your input will guide our collaboration and enable us to tailor our approach to your unique needs.
                </p>
                <p>Please remember that the SOONER we receive your information, the FASTER we can send you the final
                    proposal.
                </p>
                <p>Additionally, please note that the estimated project timeframe will start once you’ve successfully
                    supplied the request information. WE CANNOT START WORKING ON YOUR PROJECT WITHOUT THIS DATA.</p>
                <p>Thank you for your cooperation, let’s create some amazing content together!</p>
            </div>
            <form>
                <div class="row-container three-row">
                    <label for="firstname">1. First Name
                        <input type="text" id="firstname" v-model="client.firstname" readonly>
                    </label>
                    <label for="lastname">2. Last Name
                        <input type="text" id="lastname" v-model="client.lastname" readonly>
                    </label>
                    <label for="brandname">3. Brand Name
                        <input type="text" id="brandname" v-model="client.brandname" readonly>
                    </label>
                </div>
                <div class="row-container three-row">
                    <label for="sellerCentralName">4. Seller Central Name
                        <input type="text" id="sellerCentralName" v-model="client.sellerCentralName" readonly>
                    </label>
                    <label for="brandRegistryEnrolled">5. Is your brand enrolled in Brand Registry?
                        <div class="select-container">
                            <select id="brandRegistryEnrolled" v-model="client.brandRegistryEnrolled" disabled>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                            <div class="arrow-container">
                                <img :src="thinDownArrow" alt="downwards arrow">
                            </div>
                        </div>
                    </label>
                    <label for="email">6. E-mail
                        <input type="text" id="email" v-model="client.email" readonly>
                    </label>
                </div>
                <div class="row-container one-row">
                    <label for="involvementPreference">7. Do you want to be involved during the process of the project?
                        Or do you want
                        us to take care of everything?
                        <input type="text" id="involvementPreference" v-model="client.involvementPreference" readonly>
                    </label>
                </div>
                <div class="row-container two-row">
                    <label for="listingLink">8. Listing link
                        <input type="text" id="listingLink" v-model="client.listingLink" readonly>
                    </label>
                    <label for="competitorLink">9. Competitors Links (Please write at least 2 links, separate with
                        commas, not ASINs)
                        <textarea type="text" id="competitorLink" v-model="client.competitorLink" readonly></textarea>
                    </label>
                </div>
                <div class="row-container two-row">
                    <div class="product-images">
                        <label id="product-image-upload" for="product-images">10. Upload a real image of your product
                            <div role="button" aria-labelledby="product-image-upload">
                                <!-- Drag and drop area -->
                                <span>Click to upload the files or drag and drop the files here</span>
                                <input type="file" id="product-images" name="product-images[]" accept="image/*" multiple
                                    @change="handleProductImageUpload" hidden readonly>
                                <!-- <button type="button" @click="uploadImages">Subir</button> -->
                            </div>
                        </label>
                    </div>
                    <div>
                        <label for="titleKeywords">11. Write your Title Keywords (Separate with commas)
                            <textarea type="text" id="titleKeywords" v-model="client.titleKeywords" readonly></textarea>
                        </label>
                        <label for="bulletPoints">12. Write your Bullet Points (Separate with commas)
                            <textarea type="text" id="bulletPoints" v-model="client.bulletPoints" readonly></textarea>
                        </label>
                    </div>
                </div>
                <div class="row-container two-row">
                    <div class="product-description">
                        <label for="productDescription">13. Write your Product Description
                            <span>Include any relevant detail of your product: Material, quantity, what's inside the
                                package? Does it have variations?</span>
                            <textarea type="text" id="productDescription" v-model="client.productDescription"
                                readonly></textarea>
                        </label>
                    </div>
                    <div>
                        <label for="productCategory">14. In what category does your product fit?
                            <div class="select-container">
                                <select id="productCategory" v-model="client.productCategory" disabled>
                                    <option value="Home and Kitchen">Home and Kitchen</option>
                                    <option value="Health and Household">Health and Household</option>
                                    <option value="Tools and Home Improvement">Tools and Home Improvement</option>
                                    <option value="Beauty and Personal Care">Beauty and Personal Care</option>
                                    <option value="Toys and Games">Toys and Games</option>
                                    <option value="Sports and Outdoors">Sports and Outdoors</option>
                                    <option value="Other">Other</option>
                                </select>
                                <div class="arrow-container">
                                    <img :src="thinDownArrow" alt="downwards arrow">
                                </div>
                            </div>
                        </label>
                        <label for="washable">15. Is your product microwevable/machine-washable?
                            <div class="select-container">
                                <select v-model="client.washable" disabled>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                <div class="arrow-container">
                                    <img :src="thinDownArrow" alt="downwards arrow">
                                </div>
                            </div>
                        </label>
                        <label for="buyerDescription">16. Tell us about your Buyer Persona
                            <span>(Gender, Age, Marital Status, Education)</span>
                            <textarea type="text" id="buyerDescription" v-model="client.buyerDescription"
                                readonly></textarea>
                        </label>
                    </div>
                </div>
                <div class="row-container two-row">
                    <div class="branding">
                        <label id="branding-label" for="branding">17. Upload your Brand Logo / Brand Book / Anything
                            that will help with
                            Branding
                            <div role="button" aria-labelledby="branding-label">
                                <!-- Drag and drop area -->
                                <span>Click to upload the files or drag and drop the files here</span>
                                <input type="file" id="branding" name="branding[]" accept="image/*" multiple
                                    @change="handleBrandingUpload" hidden readonly>
                            </div>
                        </label>
                    </div>
                    <div>
                        <label for="diffFactor">18. What's differentiating factor of your product?
                            <textarea type="text" id="diffFactor" v-model="client.diffFactor" readonly></textarea>
                        </label>
                        <label for="competitorsFeatures">19. What things would you like to highlight that your
                            competitors are
                            currently highlighting?
                            <span>Tell us a bit more about what you like about your competitors</span>
                            <textarea type="text" id="competitorsFeatures" v-model="client.competitorsFeatures"
                                readonly></textarea>
                        </label>

                    </div>
                </div>
                <div class="row-container two-row">
                    <div class="low-sale-reason">
                        <label for="lowSaleReason">23. Why do you think your brand is not selling enough?
                            <textarea type="text" id="lowSaleReason" v-model="client.lowSaleReason" readonly></textarea>
                        </label>
                    </div>
                    <div>
                        <label for="productApproach">24. Would you like a technical or benefit approach to your product?
                            <div class="select-container">
                                <select id="productApproach" v-model="client.productApproach" disabled>
                                    <option value="1">Technical</option>
                                    <option value="0">Benefit</option>
                                </select>
                                <div class="arrow-container">
                                    <img :src="thinDownArrow" alt="downwards arrow">
                                </div>
                            </div>
                        </label>
                        <hr>
                        <label for="ppc">25. Are you planning on running PPC with our content?
                            <div class="select-container">
                                <select id="ppc" v-model="client.ppc" disabled>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                                <div class="arrow-container">
                                    <img :src="thinDownArrow" alt="downwards arrow">
                                </div>
                            </div>
                        </label>
                    </div>
                </div>

            </form>
        </div>

    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import shareitLogo from '../../../../../src/assets/Share-it-studio_logo 1.svg'
import thinDownArrow from '../../../../../src/assets/thin-down-arrow.svg';
import axios from "axios";
import { useRoute, useRouter } from 'vue-router';

export default {
    name: 'ProposalForm',
    props: ['formToken'],

    setup() {


        const route = useRoute();
        const router = useRouter();
        const shareitLogoRef = ref(shareitLogo);
        const thinDownArrowRef = ref(thinDownArrow);
        onMounted(async () => {
            try {
                const response = await axios.get('form/' + route.params.formToken);
                console.log(response)
                const newClient = response.data
                client.value.companyName = newClient.company_name
                client.value.codeNum = newClient.code_num
                client.value.formToken = route.params.formToken
                client.value.firstname = newClient.first_name
                client.value.lastname = newClient.last_name
                client.value.brandname = newClient.brand_name;
                client.value.sellerCentralName = newClient.seller_central_name;
                client.value.brandRegistryEnrolled = newClient.brand_enrolled;
                client.value.email = newClient.email;
                client.value.involvementPreference = newClient.process_involvement;
                client.value.listingLink = newClient.listing_link;
                client.value.competitorLink = newClient.competitor_links;
                client.value.productImage = [];
                client.value.titleKeywords = newClient.title_key_words;
                client.value.bulletPoints = newClient.bullet_points;
                client.value.productDescription = newClient.product_description;
                client.value.productCategory = newClient.category;
                client.value.washable = newClient.washeable;
                client.value.buyerDescription = newClient.description_buyer_persona;
                client.value.branding = [];
                client.value.diffFactor = newClient.differentiating_factor;
                client.value.competitorsFeatures = newClient.highlighting_competitors;
                client.value.lowSaleReason = newClient.selling_enough;
                client.value.productApproach = newClient.technical_approach;
                client.value.specialPackaging = newClient.outstanding_packaging;
                client.value.ppc = newClient.planning_ppc;
                client.value.brandStory = newClient.branding_story_service;
                client.value.packagingDesign = newClient.packaging_design;
                client.value.videoIncluded = newClient.video;
             

            } catch (err) {
                router.push('/')
            }

            // const modelPropSet=await axios.post(agenciaBaseUrl+'api/v1/retrieve-model-prop-set',{token:client.value.formToken});
            // console.log(modelPropSet)
            // client.value.model=modelPropSet.model;
            // client.value.props=modelPropSet.props;
            // client.value.set=modelPropSet.set;
            // console.log(client.value.model,client.value.set)
        })
        const client = ref({
            companyName: '',
            codeNum: '',
            formToken: '',
            firstname: '',
            lastname: '',
            brandname: '',
            sellerCentralName: '',
            brandRegistryEnrolled: '',
            email: '',
            involvementPreference: '',
            listingLink: '',
            competitorLink: '',
            productImage: [],
            titleKeywords: '',
            bulletPoints: '',
            productDescription: '',
            productCategory: '',
            washable: '',
            buyerDescription: '',
            branding: [],
            diffFactor: '',
            competitorsFeatures: '',
            lowSaleReason: '',
            productApproach: '',
            specialPackaging: '0',
            ppc: '',
            brandStory: '0',
            packagingDesign: '0',
            videoIncluded: '0',
            model: '',
            props: '',
            set: ''
        });

        // const handleProductImageUpload = async (event) => {
        //     const files = event.target.files;

        //     client.value.productImage = [...client.value.productImage, ...files]

        //     //console.log(image.value.productImage); // Verifica si image.value.productImage contiene los archivos correctamente
        // };
        // const handleBrandingUpload = async (event) => {
        //     const files = event.target.files;
        //     client.value.branding = [...client.value.branding, ...files]
        // };

        // const uploadImages = async () => {
        //     const formData = new FormData();
        //     try {
        //         let files = image.value.productImage;
        //         for (let i = 0; i < files.length; i++) {
        //             formData.append('files[]', files[i]);
        //         }
        //         console.log(formData); // Verifica si image.value.productImage

        //         const response = await axios.post('/submitImage', formData, {
        //             headers: {
        //                 'Content-Type': 'multipart/form-data'
        //             }
        //         });
        //         console.log(response.data);
        //         // Aquí puedes manejar la respuesta de la API, como mostrar un mensaje de éxito
        //     } catch (error) {
        //         console.error('Error al subir imágenes:', error);
        //         // Aquí puedes manejar los errores, como mostrar un mensaje de error al usuario
        //     }
        // };

        // function toFormData(clientDataObject) {
        //     const formData = new FormData();
        //     Object.keys(clientDataObject).forEach(key => {
        //         const value = clientDataObject[key];
        //         if (key==='productImage'||key==='branding') { // For arrays, append each item individually
        //             for (let i = 0; i < value.length; i++) {
        //                 formData.append(`${key}[${i}]`, value[i]);
        //             }
        //         } else {
        //             formData.append(key, value);
        //         }
        //     });
        //     return formData;
        // }


        // const submitForm = async () => {
        //     try {

        //         let res = await axios.post(agenciaBaseUrl+'api/v1/submitForm/proposal', toFormData(client.value),{
        //             headers: {
        //                 'Content-Type': 'multipart/form-data'
        //             }
        //         });
        //         if (res.status == 201) {
        //             axios.post(agenciaProposalBaseUrl+'proposal', client.value);
        //             // location.replace(agenciaBaseUrl+"form/status")
        //         }
        //         // Realizar acciones adicionales después de que la solicitud sea exitosa
        //     } catch (error) {
        //         if (error.response && error.response.status === 422) {
        //             // Si recibimos un error 422 (error de validación), mostramos los mensajes de error
        //             const validationErrors = error.response.data.errors;
        //             for (let key in validationErrors) {
        //                 toastr.error(validationErrors[key]);
        //             }
        //         } else {
        //             // Otros errores
        //             console.error('Error:', error);
        //         }
        //     }
        // };


        return {
            // companyName,
            // codeNum,
            client,
            // handleProductImageUpload,
            // handleBrandingUpload,
            // submitForm,
            // uploadImages,
            shareitLogo: shareitLogoRef,
            thinDownArrow: thinDownArrowRef
        };
    }
};
</script>

<style scoped>
* {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    box-sizing: border-box;
}

.client-involved-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: white;
    padding: 70px 0;
    row-gap: 50px;
}

.aux-content-container {
    width: 65%;
    min-width: 1190px;
    padding: 80px;
    display: flex;
    flex-direction: column;
    row-gap: 70px;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.form-id span {
    color: #193939;
    font-size: 20px;
    font-weight: bold;
}

.form-id div span:last-child {
    font-weight: lighter;
    font-style: italic;
}

.row-container>label[for='brandRegistryEnrolled'] {
    overflow: visible;
    text-wrap: nowrap;
}

label[for="brandRegistryEnrolled"] .select-container {
    padding-left: 12px;
}

label span {
    line-height: 1;
    font-weight: lighter;
}

form,
.form-description {
    width: 974px;
}

.form-description {
    color: #193939;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.form-description h1 {
    margin-bottom: 15px;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 15px;
}

.row-container {
    display: flex;
    row-gap: 15px;

}

.row-container div {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.row-container .select-container,
.row-container .arrow-container {
    flex-direction: row;
}

.three-row {
    column-gap: 37px;
    height: 55px;
}

label {
    font-size: 15px;
    font-weight: bold;
    color: #193939;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    line-height: 1;
}

textarea {
    resize: none;
}

.three-row label {
    width: 300px;
    max-width: 300px;
}

.select-container {
    position: relative;
    width: 56px;
    height: 33px;
    border: 1px solid #193939;
    border-radius: 4px;
    overflow: hidden;
}

.select-container select {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px 8px;
    border-radius: 4px;
    cursor: pointer;
}

.arrow-container {
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
    bottom: 0;
    display: flex;
    align-items: center;
    pointer-events: none;

}

.arrow-container img {
    height: 10px;
}

textarea,
input[type="text"],
input[type="email"] {

    border: 1px solid #193939;
    border-radius: 5px;
    color: #193939;
    font-weight: 500;
    font-size: 15px;
    padding-left: 12px;
}

.three-row input[type="text"] {
    width: 100%;
    height: 60%;
    padding-left: 12px;
}

.three-row .select-container {
    width: 100%;
    height: 60%;
}

select {
    padding-left: 12px;
}

.one-row,
.one-row label {
    width: 974px;
    height: 55px;
}

.one-row input,
.one-row select {
    height: 33px;
}

.two-row {
    display: flex;
    width: 974px;
    column-gap: 24px;
}

.two-row label {
    width: 475px;
    height: 126px;
}

.two-row input,
.two-row textarea,
.two-row select {
    height: 104px;
}

.scenario-features label {
    height: 55px;
}

.product-images,
.product-images label {
    height: 267px;
}

.product-images input,
.product-images select {
    height: 245px;
}

.product-images+div {
    height: 267px;
}

.product-images+div label {
    height: 126px;
}

.product-images div,
.branding div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed #193939;
    height: 100%;
    border-radius: 5px;
}

.product-images span,
.branding span {
    width: 70%;
    text-align: center;
}

.product-description label,
.product-description+div {
    height: 288px;
}

.product-description textarea,
.product-description select {
    height: 277px;
}

.product-description+div input,
.product-description+div select {
    height: 33px;
}

.product-description+div label {
    height: 55px;
}

.product-description+div label:last-child {
    height: 148px;
}

.product-description+div label:last-child input,
.product-description+div label:last-child select {
    height: 104px;
}

.branding,
.branding label {
    height: 303px;
}

.branding input {
    height: 259px;
}

.branding+div {
    height: 306px;
}

.branding+div label {
    height: 126px;
}

.branding+div label:last-child {
    height: 165px;
}

.low-sale-reason,
.low-sale-reason label,
.low-sale-reason+div {
    height: 174px;
}

.low-sale-reason+div {
    row-gap: 0px;
}

.low-sale-reason textarea,
.low-sale-reason select {
    height: 118px;
}

.low-sale-reason+div label {
    min-height: 54px;
    max-height: 71px;
}

.low-sale-reason+div input,
.low-sale-reason+div select {
    height: 33px;
}

.thin-row,
.thin-row label {
    height: 54px;
}

.thin-row input,
.thin-row select {
    height: 33px;
}

/* label {
    flex: 1;
    height: 100%;
    font-size: 15px;
    font-weight: bold;
    color: #193939;
    display: flex;
    flex-direction: column;
    row-gap: 10%;
}

input[type="text"],
input[type="email"],
select {
    width: 100%;
    height: 60%;
    border: 1px solid #193939;
    border-radius: 5px;
    color: #193939;
    font-weight: 500;
    font-size: 15px;
    padding-left: 12px;
} */

.product-image input,
.branding input {
    border: 1px dashed #193939;
    border-radius: 5px;

    text-align: center;

    transition: all 0.2s;
}



.btn-aux {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.btn-aux button {
    width: 116px;
    height: 40px;
    background-color: #193939;
    border-radius: 5px;
    font-size: 20px;
    font-weight: bold;
    color: #F2F2EA;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-description+div .select-container,
.low-sale-reason+div .select-container,
.thin-row .select-container {
    width: 100%;
}
</style>
